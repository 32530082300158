import { BEFORE_UPLOAD_METHOD_CLICK_ADVICES } from 'employee/components/document_groups/DocumentUploadFlow/advices'
import { useCallback, useState } from 'react'

export const useDocumentAdvice = () => {
  const [showAdvice, setShowAdvice] = useState<boolean>(false)
  const [currentSlug, setCurrentSlug] = useState<string>('')

  const AdviceComponent = BEFORE_UPLOAD_METHOD_CLICK_ADVICES[currentSlug]

  const setShowAdviceIfNeeded = useCallback((slug: string) => {
    if (Object.keys(BEFORE_UPLOAD_METHOD_CLICK_ADVICES).includes(slug)) {
      setCurrentSlug(slug)
      setShowAdvice(true)
    }
  }, [])

  return { AdviceComponent, showAdvice, setShowAdviceIfNeeded, hideAdvice: () => setShowAdvice(false) }
}
