import React from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import i18next from 'i18next'
import AgencyReferralForm, { ReferralForm } from 'employee/components/AgencyReferralForm'
import { trackEvent } from 'utils/amplitude'
import { useAgencyReferrals } from 'employee/queries/agency_referrals'
import Button from 'components/Button'

const PublicAgencyReferralShow = () => {
  const [search] = useSearchParams()
  const { mutate: createAgencyReferral } = useAgencyReferrals({
    onSuccess: () => toast.success(i18next.t('employee.referral.form.success')),
  })
  const navigate = useNavigate()

  const onSubmit = (agencyInfo: ReferralForm) => {
    trackEvent('agency_referral_email')
    createAgencyReferral({ ...agencyInfo, employeeToken: search.get('token') ?? undefined })
  }

  return (
    <div className="p-6">
      <AgencyReferralForm onSubmit={onSubmit} />
      <Button name="login" uiStyle="secondary" onClick={() => navigate('/login')} className="w-full mt-2">
        {i18next.t('common.log_in')}
      </Button>
    </div>
  )
}

export default PublicAgencyReferralShow
