import React from 'react'
import AgencyReferralForm, { ReferralForm } from 'employee/components/AgencyReferralForm'
import { trackEvent } from 'utils/amplitude'
import { useAgencyReferrals } from 'employee/queries/agency_referrals'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import i18next from 'i18next'

const AgencyReferralShow = () => {
  const navigate = useNavigate()
  const { mutate: createAgencyReferral } = useAgencyReferrals({
    onSuccess: () => {
      navigate(-1)
      toast.success(i18next.t('employee.referral.form.success'))
    },
  })

  const onSubmit = (agencyInfo: ReferralForm) => {
    trackEvent('agency_referral_logged_in')
    createAgencyReferral(agencyInfo)
  }

  return (
    <div className="p-6">
      <AgencyReferralForm onSubmit={onSubmit} />
    </div>
  )
}

export default AgencyReferralShow
