import React from 'react'
import Text from 'components/Text'
import Tag, { TagUiStyle } from 'components/Tag'
import i18next from 'i18next'
import { EmployeeDocumentGroupStatus } from 'employee/types/document_groups'

const LABEL_BY_STATUS: Record<EmployeeDocumentGroupStatus, string> = {
  valid: 'document_groups.status.valid',
  missing: 'document_groups.status.missing',
  optional: 'document_groups.status.optional',
  non_compliant: 'document_groups.status.non_compliant',
  missing_name: 'document_groups.status.missing_name',
  missing_double_sided: 'document_groups.status.missing_double_sided',
  missing_signature: 'document_groups.status.missing_signature',
  missing_date: 'document_groups.status.missing_date',
  expired: 'document_groups.status.expired',
  unreadable: 'document_groups.status.unreadable',
  fraudulent: 'document_groups.status.fraudulent',
  not_owned: 'document_groups.status.not_owned',
}

const TAG_UI_STYLE_BY_STATUS: Record<EmployeeDocumentGroupStatus, TagUiStyle> = {
  valid: 'success',
  missing: 'error',
  optional: 'neutral',
  expired: 'error',
  non_compliant: 'error',
  missing_name: 'error',
  missing_double_sided: 'error',
  missing_signature: 'error',
  missing_date: 'error',
  unreadable: 'error',
  fraudulent: 'error',
  not_owned: 'warning',
}

const StatusTag = ({ status }: { status: EmployeeDocumentGroupStatus }) => (
  <Tag uiStyle={TAG_UI_STYLE_BY_STATUS[status]} withDot>
    <Text type="text">{i18next.t(LABEL_BY_STATUS[status])}</Text>
  </Tag>
)

export default StatusTag
