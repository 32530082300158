import React from 'react'
import IdentityProofAdviceModal from './IdentityProofAdviceModal'
import IdentityPhotoAdviceModal from './IdentityPhotoAdviceModal'
import AddressProofAdviceModal from './AddressProofAdviceModal'
import DeclarationOfHonorAdviceModal from './DeclarationOfHonorAdviceModal'

export const PHOTO_CLICK_ADVICES: Record<
  string,
  React.ComponentType<{ onClose: () => void; onContinueClick: () => void }>
> = {
  identity_proof: IdentityProofAdviceModal,
}

export type DocumentAdviceType = React.ComponentType<{ onClose: () => void; onContinueClick: () => void }>

export const BEFORE_UPLOAD_METHOD_CLICK_ADVICES: Record<string, DocumentAdviceType> = {
  employee_address_proof: AddressProofAdviceModal,
  accomodation_provider_address_proof: AddressProofAdviceModal,
  declaration_of_honour: DeclarationOfHonorAdviceModal,
  identity_photo: IdentityPhotoAdviceModal,
}
