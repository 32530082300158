import { useMutation } from '@tanstack/react-query'
import httpClient from 'utils/http-client'

type MutationProps = {
  contactName: string
  agencyName: string
  phoneNumber?: string
  email?: string
  employeeToken?: string
}

type AgencyReferralsProps = { onSuccess?: () => void; onError?: () => void }

export const useAgencyReferrals = ({ onSuccess, onError }: AgencyReferralsProps = {}) =>
  useMutation({
    mutationFn: ({ contactName, agencyName, phoneNumber, email, employeeToken }: MutationProps) =>
      httpClient('/agency_referral', {
        method: 'post',
        body: {
          contact_name: contactName,
          agency_name: agencyName,
          phone_number: phoneNumber,
          email,
          employee_token: employeeToken,
        },
      }),
    onError,
    onSuccess,
  })
