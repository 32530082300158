import Button from 'components/Button'
import Input from 'components/Input'
import Text from 'components/Text'
import i18next from 'i18next'
import React, { useState } from 'react'

export type ReferralForm = {
  agencyName: string
  contactName: string
  email: string
  phoneNumber: string
}

type AgencyReferralFormProps = {
  onSubmit: (form: ReferralForm) => void
}

const AgencyReferralForm = ({ onSubmit }: AgencyReferralFormProps) => {
  const [state, setState] = useState({
    agencyName: '',
    contactName: '',
    email: '',
    phoneNumber: '',
  })

  const handleChange = (key: keyof ReferralForm) => (value: string) =>
    setState((prevState) => ({ ...prevState, [key]: value }))

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(state)
    setState({
      agencyName: '',
      contactName: '',
      email: '',
      phoneNumber: '',
    })
  }

  return (
    <div>
      <Text className="mb-2" type="title">
        {i18next.t('employee.referral.title')}
      </Text>
      <Text className="mb-6" type="subtext">
        {i18next.t('employee.referral.form.explanation')}
      </Text>
      <form onSubmit={handleOnSubmit}>
        <Input
          className="w-full mb-2"
          name="agencyName"
          label={i18next.t('employee.referral.form.agency_name_label')}
          placeholder={i18next.t('employee.referral.form.agency_name_placeholder')}
          value={state.agencyName}
          onChange={handleChange('agencyName')}
          required
        />
        <Input
          className="w-full mb-2"
          name="contactName"
          label={i18next.t('employee.referral.form.contact_name_label')}
          placeholder={i18next.t('employee.referral.form.contact_name_placeholder')}
          value={state.contactName}
          onChange={handleChange('contactName')}
          required
        />
        <Input
          className="w-full mb-2"
          name="email"
          label={i18next.t('employee.referral.form.email_label')}
          placeholder={i18next.t('employee.referral.form.email_placeholder')}
          value={state.email}
          type="email"
          onChange={handleChange('email')}
        />
        <Input
          className="w-full mb-4"
          name="phoneNumber"
          label={i18next.t('employee.referral.form.phone_number_label')}
          placeholder={i18next.t('employee.referral.form.phone_number_placeholder')}
          value={state.phoneNumber}
          type="phone"
          onChange={handleChange('phoneNumber')}
        />
        <Button className="w-full" type="submit" name="invite_agency">
          {i18next.t('employee.referral.info.button')}
        </Button>
      </form>
    </div>
  )
}

export default AgencyReferralForm
