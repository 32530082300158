import React from 'react'

import Divider from 'components/Divider'
import Pill from 'components/Pill'
import Text from 'components/Text'
import { DocumentGroup } from 'employee/types/document_groups'
import Row from './Row'

type CategoryProps = {
  id: string
  title: string
  documentGroups: DocumentGroup[]
  onClick: (documentGroup: DocumentGroup) => void
}

const Category = ({ documentGroups, onClick, id, title }: CategoryProps) => (
  <div id={id} className="py-4 w-full">
    <div className="flex items-center gap-3">
      <Text type="subtitle">{title}</Text>
      <Pill count={documentGroups.length} isActive={false} />
    </div>
    <Divider className="mt-4" />
    {documentGroups.map((documentGroup) => (
      <Row
        key={documentGroup.id}
        documentGroup={documentGroup}
        onClick={() => onClick(documentGroup)}
        status={documentGroup.status}
      />
    ))}
  </div>
)

export default Category
