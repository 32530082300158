import { useMutation } from '@tanstack/react-query'
import httpClient from 'utils/http-client'

type UseDocumentUploadArgs = { onSuccess?: () => void; onError?: (error: Error) => void }

type UseDocumentUploadMutationArgs = { documentGroupId: string }

export const useDocumentNotOwned = ({ onSuccess, onError }: UseDocumentUploadArgs = {}) =>
  useMutation({
    mutationFn: async ({ documentGroupId }: UseDocumentUploadMutationArgs) =>
      httpClient(`/document_groups/${documentGroupId}/not_owned_documents`, { method: 'post' }),
    onSuccess,
    onError,
  })
