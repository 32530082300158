import React, { PropsWithChildren, useEffect } from 'react'
import classNames from 'classnames'
import { Outlet, NavLink } from 'react-router-dom'
import Divider from 'components/Divider'
import Text from 'components/Text'
import i18next from 'i18next'
import Icon from 'components/Icon'
import Chip from 'components/Chip'
import { useDocumentGroups } from 'employee/queries/document_groups'
import { useAgencies } from 'employee/queries/agencies'
import { useEmployee } from 'employee/queries/employees'
import { setUserId } from 'utils/amplitude'

const BadgeNotification = ({ count }: { count: number }) => (
  <Chip className="bg-badge text-white w-6 h-6 absolute -top-2 right-2 outline outline-white outline-2">{count}</Chip>
)

const Skeleton = ({ children }: PropsWithChildren) => {
  const navLinkClasses = ({ isActive }: { isActive: boolean }) =>
    classNames('flex flex-col items-center relative', {
      'text-primary': isActive,
      'text-textSecondary': !isActive,
    })

  const { data: documentGroups } = useDocumentGroups()
  const { data: agencies } = useAgencies()
  const { data: employee } = useEmployee()

  useEffect(() => {
    if (!employee) return
    setUserId(employee.id)
  }, [employee])

  const needsActionDocumentGroupCount = documentGroups?.needsAction.length ?? 0
  const agencyPendingInviteCount = agencies.pending.length

  return (
    <div className="flex flex-col h-dvh">
      <main className="flex-grow overflow-auto">{children ?? <Outlet />}</main>
      <Divider />
      <nav className="flex shrink-0 justify-evenly items-center h-20 py-2">
        <NavLink className={navLinkClasses} to="/document_groups">
          {needsActionDocumentGroupCount > 0 ? <BadgeNotification count={needsActionDocumentGroupCount} /> : null}
          <Icon name="home_storage_filled" className="w-8 h-8" />
          <Text type="text">{i18next.t('employee.bottom_bar.documents')}</Text>
        </NavLink>
        <NavLink className={navLinkClasses} to="/agencies">
          {agencyPendingInviteCount > 0 ? <BadgeNotification count={agencyPendingInviteCount} /> : null}
          <Icon name="location_home" className="w-8 h-8" />
          <Text type="text">{i18next.t('employee.bottom_bar.agencies')}</Text>
        </NavLink>
        <NavLink className={navLinkClasses} to="/account">
          <Icon name="account_circle" className="w-8 h-8" />
          <Text type="text">{i18next.t('employee.bottom_bar.account')}</Text>
        </NavLink>
      </nav>
    </div>
  )
}

export default Skeleton
