import { sendError } from 'utils/appsignal'
import { isAbortError } from 'utils/errors'

const PWNED_PASSWORDS_RANGE_API = 'https://api.pwnedpasswords.com/range/'

const sha1 = (string: string): Promise<ArrayBuffer> => {
  const buffer = new TextEncoder().encode(string)
  return crypto.subtle.digest('SHA-1', buffer)
}

export const getHexSha1 = (rawPassword: string): Promise<string> =>
  sha1(rawPassword).then((sha1Password) =>
    Array.from(new Uint8Array(sha1Password))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase()
  )

const isSuffixInPwnedPasswords = (suffix: string, pwnedApiResult: string): boolean => {
  const lines = pwnedApiResult.split('\r\n')

  const line = lines.find((entry) => {
    const hashSuffix = entry.split(':')[0]
    return hashSuffix === suffix
  })
  if (!line) {
    return false
  }
  const count = line.split(':')[1]
  if (count !== '0') {
    return true
  }
  return false
}

type PasswordStrength =
  | {
      isStrongEnough: true
    }
  | {
      isStrongEnough: false
      reason: 'too_short' | 'pwned'
    }

export const checkPasswordStrength = async (password: string, signal: AbortSignal): Promise<PasswordStrength> => {
  if (password.length < 8) {
    return { isStrongEnough: false, reason: 'too_short' }
  }
  const hexSha1Password = await getHexSha1(password)
  const prefix = hexSha1Password.slice(0, 5)
  const suffix = hexSha1Password.slice(5)
  const response: Response | { ok: false } = await fetch(`${PWNED_PASSWORDS_RANGE_API}${prefix}`, {
    headers: {
      'Add-Padding': 'true',
    },
    signal,
  }).catch((error) => {
    if (isAbortError(error)) {
      throw error
    }
    sendError(error)
    return { ok: false }
  })

  if (!response.ok) {
    // Do not block the user if the API is down
    return { isStrongEnough: true }
  }

  const result = await response.text()

  if (isSuffixInPwnedPasswords(suffix, result)) {
    return { isStrongEnough: false, reason: 'pwned' }
  }
  return { isStrongEnough: true }
}
