import React from 'react'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import classNames from 'classnames'
import Icon from './Icon'
import Text from './Text'

type CheckBoxProps = {
  name: string
  label: string | React.ReactNode
  checked: boolean
  onChange: (state: boolean) => void
  containerClassName?: string
}

const CheckBox = ({ name, label, onChange, checked, containerClassName }: CheckBoxProps) => (
  <div className={classNames('flex items-center', containerClassName)}>
    <RadixCheckbox.Root
      className="flex h-6 w-6 appearance-none items-center justify-center rounded-1 bg-white border-borderMedium border shrink-0 grow-0"
      checked={checked}
      onCheckedChange={onChange}
      id={name}
      name={name}
    >
      <RadixCheckbox.Indicator>
        <Icon name="check" size="medium" className="text-primary" />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
    <label htmlFor={name}>
      <Text type="smalltext" className="ml-2">
        {label}
      </Text>
    </label>
  </div>
)

export default CheckBox
