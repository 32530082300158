import React, { useState } from 'react'
import i18next from 'i18next'
import Icon, { IconName } from 'components/Icon'
import Button from 'components/Button'
import Text from 'components/Text'
import Chip from 'components/Chip'
import CheckBox from 'components/CheckBox'
import { CGU_LINK } from 'employee/utils/legal_links'
import { trackEvent } from 'utils/amplitude'
import Header from './Header'

type DisclaimerRowProps = {
  text: string
  icon: IconName
}

const DisclaimerRow = ({ text, icon }: DisclaimerRowProps) => (
  <div className="flex flex-row items-center mb-4 gap-3">
    <Chip
      size="large"
      className="bg-white rounded-full flex items-center justify-center self-center shrink-0 grow-0 border-[1px] border-borderMedium"
    >
      <Icon name={icon} uiStyle="success" size="medium" />
    </Chip>
    <Text type="text">{text}</Text>
  </div>
)

type DisclaimerProps = {
  onNext: () => void
  agencyName: string
}

const HtmlLabel = ({ htmlText }: { htmlText: string }) => (
  // eslint-disable-next-line react/no-danger
  <span dangerouslySetInnerHTML={{ __html: htmlText }} />
)

const Disclaimer = ({ onNext, agencyName }: DisclaimerProps) => {
  const [termsEnabled, setTermsEnabled] = useState(false)
  const [sharingEnabled, setSharingEnabledEnabled] = useState(false)

  const handleOnNextClick = () => {
    trackEvent('signup-welcome-click')
    onNext()
  }

  return (
    <div className="grow flex flex-col">
      <div className="w-full flex flex-col grow pt-12">
        <Header
          icon="celebration"
          title={i18next.t(`employee.signup.disclaimer.title`)}
          subtitle={i18next.t('employee.signup.disclaimer.subtitle', { agency_name: agencyName })}
        />
        <div className="mb-6">
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.add_documents')} icon="assignement_add" />
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.pick_agencies')} icon="admin_panel_settings" />
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.update_documents')} icon="update" />
        </div>
      </div>

      <CheckBox
        onChange={setTermsEnabled}
        name="skribe_terms"
        label={<HtmlLabel htmlText={i18next.t('employee.signup.disclaimer.terms_html', { link: CGU_LINK })} />}
        containerClassName="mb-3"
        checked={termsEnabled}
      />
      <CheckBox
        onChange={setSharingEnabledEnabled}
        name="share_agency"
        label={
          <HtmlLabel
            htmlText={i18next.t('employee.signup.disclaimer.share_agency_button_html', { agency_name: agencyName })}
          />
        }
        containerClassName="mb-3"
        checked={sharingEnabled}
      />
      <Button
        type="button"
        onClick={handleOnNextClick}
        name={i18next.t('common.continue')}
        className="my-6 w-full"
        disabled={!termsEnabled || !sharingEnabled}
      >
        {i18next.t('common.continue')}
      </Button>
    </div>
  )
}

export default Disclaimer
