import { initAmplitude } from './amplitude'
import { sendError } from './appsignal'

type CookieType = 'necessary' | 'functional' | 'analytics' | 'performance' | 'advertisement' | 'other'

type ConsentUpdateEvent = {
  detail?: {
    accepted?: CookieType[]
    rejected?: CookieType[]
  }
}

type ConsentLoadEvent = {
  detail?: {
    categories?: Record<CookieType, boolean>
  }
}

const onAnalyticsAccepted = () => {
  initAmplitude('employee')
}

const consentUpdate = (consentUpdateEvent: ConsentUpdateEvent) => {
  // Payload data has changed without beeing handled on our side
  if (!consentUpdateEvent.detail?.accepted) {
    sendError(new Error('Consent update event does not contain accepted cookies'))
    return
  }

  if (consentUpdateEvent.detail.accepted.includes('analytics')) {
    onAnalyticsAccepted()
  }
}

export const setupCookiesCallback = () => {
  document.addEventListener('cookieyes_banner_load', (eventData) => {
    document.addEventListener('cookieyes_consent_update', (eventUpdateData) =>
      consentUpdate(eventUpdateData as unknown as ConsentUpdateEvent)
    )
    const consentEvent = eventData as unknown as ConsentLoadEvent

    // Payload data has changed without beeing handled on our side
    if (!consentEvent.detail?.categories) {
      sendError(new Error('Consent categories not found'))
      return
    }

    if (consentEvent.detail.categories.analytics) {
      onAnalyticsAccepted()
    }
  })
}
