import React from 'react'
import i18next from 'i18next'
import Icon from 'components/Icon'
import Divider from 'components/Divider'
import Text from 'components/Text'
import { EmployeeDocumentGroupStatus, DocumentGroup } from 'employee/types/document_groups'
import StatusTag from 'employee/components/document_groups/StatusTag'
import ClickableDiv from 'components/ClickableDiv'
import DocumentIcon from 'components/DocumentIcon'

type RowProps = {
  date?: string
  status: EmployeeDocumentGroupStatus
  documentGroup: DocumentGroup
  onClick: (value: DocumentGroup) => void
}

const Row = ({ date, status, documentGroup, onClick }: RowProps) => (
  <>
    <ClickableDiv
      className="flex py-3 items-center justify-between gap-4 cursor-pointer"
      label={i18next.t('employee.document_groups.add_document_aria_label', { label: documentGroup.label })}
      onClick={() => {
        onClick(documentGroup)
      }}
    >
      <DocumentIcon label={documentGroup.label} slug={documentGroup.slug} status={status} />
      <div className="grow">
        <Text type="text">{documentGroup.label}</Text>
        {date && <Text type="subtext">{date}</Text>}
      </div>
      <StatusTag status={status} />
      <Icon name="chevron_right" className="shrink-0" />
    </ClickableDiv>
    <Divider />
  </>
)

export default Row
