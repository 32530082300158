import React from 'react'
import { toast } from 'react-hot-toast'
import Button from 'components/Button'
import { TAgency } from 'employee/types/agencies'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import Text from 'components/Text'
import { capitalizeFirstLetter } from 'utils/string'
import Icon from 'components/Icon'
import { useAcceptInviteAgencies } from 'employee/queries/agencies'
import ClickableDiv from 'components/ClickableDiv'
import { trackEvent } from 'utils/amplitude'

type RowPendingProps = {
  agency: TAgency
  onClick: (agency: TAgency) => void
}

const RowPending = ({ agency, onClick }: RowPendingProps) => {
  const navigate = useNavigate()
  const { mutate: acceptInvite } = useAcceptInviteAgencies(agency.id, {
    onSuccess: () => {
      navigate('/document_groups')
      toast.success(i18next.t('employee.agency_list.invite_accepted'))
    },
    onError: () => {
      toast.error(i18next.t('common.unexpected_error'))
    },
  })

  const onAcceptClick = () => {
    acceptInvite()
    trackEvent('accept-agency-click', {
      agencyId: agency.id,
    })
  }

  return (
    <div className="bg-backgroundPrimary border border-borderMedium rounded-lg p-4 mb-2">
      <ClickableDiv label={agency.name} onClick={() => onClick(agency)} className="flex items-center gap-4 mb-2">
        <img
          className="shrink-0 max-w-[30%]"
          src={agency.logo}
          alt={i18next.t('employee.agency_list.agency_logo', { name: agency.name })}
        />
        <div className="flex flex-col overflow-hidden grow">
          <Text type="text">{capitalizeFirstLetter(agency.name)}</Text>
          <Text type="subtext" className="text-ellipsis whitespace-nowrap overflow-hidden">
            {agency.address}
          </Text>
        </div>
        <Icon name="chevron_right" className="w-8 h-8" />
      </ClickableDiv>
      <Button className="w-full" type="button" name={`accept ${agency.name}`} onClick={onAcceptClick}>
        {i18next.t('common.accept')}
      </Button>
    </div>
  )
}

export default RowPending
