import React from 'react'
import httpClient from 'utils/http-client'
import i18next from 'i18next'
import { useEmployee } from 'employee/queries/employees'
import Text from 'components/Text'
import Icon, { IconName } from 'components/Icon'
import ClickableDiv from 'components/ClickableDiv'
import Divider from 'components/Divider'
import { useNavigate } from 'react-router-dom'
import { CGU_LINK, LEGAL_NOTICE_LINK } from 'employee/utils/legal_links'

type AccountSectionDescriptionProps = {
  icon: IconName
  title: string
  subtitle?: string
}

const AccountSectionDescription = ({ icon, title, subtitle }: AccountSectionDescriptionProps) => (
  <>
    <Icon size="medium" name={icon} className="mr-3" />
    <div className="flex flex-col grow">
      <Text uiStyle="bold">{title}</Text>
      {subtitle && <Text type="subtext">{subtitle}</Text>}
    </div>
  </>
)

type WithOnClick = {
  onClick: () => void
  link?: never
}

type WithLink = {
  onClick?: never
  link: string
}

type AccountSectionProps = {
  icon: IconName
  title: string
  subtitle?: string
} & (WithOnClick | WithLink)

const AccountSection = ({ icon, onClick, link, title, subtitle }: AccountSectionProps) => {
  const className = 'flex flex-row items-center py-3'
  if (onClick) {
    return (
      <ClickableDiv className={className} onClick={onClick} label={title}>
        <AccountSectionDescription icon={icon} title={title} subtitle={subtitle} />
      </ClickableDiv>
    )
  }
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={className}>
      {/* eslint-disable-line jsx-a11y/control-has-associated-label */}
      <AccountSectionDescription icon={icon} title={title} subtitle={subtitle} />
    </a>
  )
}

const Account = () => {
  const { data: employee } = useEmployee()
  const navigate = useNavigate()

  const logout = () => {
    httpClient('/session', { method: 'delete' }).then(() => {
      window.location.href = '/'
    })
  }

  return (
    <div className="flex flex-col h-full px-6 pt-12 pb-2">
      <div className="mb-7">
        <Text type="title">{i18next.t('employee.account.title')}</Text>
        <Text type="subtext">{employee.email}</Text>
      </div>
      <div>
        <AccountSection
          title={i18next.t('employee.account.support_title')}
          subtitle={i18next.t('employee.account.support_subtitle')}
          link="mailto:support@skribe.eu"
          icon="support"
        />
        <Divider className="my-1" />

        <AccountSection
          title={i18next.t('employee.account.privacy.title')}
          subtitle={i18next.t('employee.account.privacy.subtitle')}
          onClick={() => navigate('/account/privacy_settings')}
          icon="lock"
        />
        <Divider className="my-1" />

        <AccountSection title={i18next.t('employee.legal.cgu')} link={CGU_LINK} icon="arrow_outward" />
        <Divider className="my-1" />
        <AccountSection title={i18next.t('employee.legal.notice')} link={LEGAL_NOTICE_LINK} icon="arrow_outward" />
        <Divider className="my-1" />

        <AccountSection title={i18next.t('common.log_out')} onClick={logout} icon="logout" />
      </div>
    </div>
  )
}

export default Account
