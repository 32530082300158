import React, { PropsWithChildren, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const NO_REDIRECT_ROUTES = ['/agency_referrals']

const PublicRoutes = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (window.logged_in && !NO_REDIRECT_ROUTES.includes(location.pathname)) {
      navigate('/')
    }
  }, [location.pathname])
  return children ?? <Outlet />
}

export default PublicRoutes
