import React from 'react'
import { Outlet } from 'react-router-dom'
import useSpaUpdater from 'hooks/useSpaUpdater'
import { useBreadcrumbsUpdater } from 'utils/appsignal'

const RouteWatcher = () => {
  useSpaUpdater()
  useBreadcrumbsUpdater()

  return <Outlet />
}

export default RouteWatcher
