import Button from 'components/Button'
import Text from 'components/Text'
import i18next from 'i18next'
import React from 'react'

type AgencyReferralInfoProps = {
  onClick: () => void
}

const AgencyReferralInfo = ({ onClick }: AgencyReferralInfoProps) => (
  <div className="bg-backgroundPrimary border border-borderMedium rounded-lg p-4 mb-2">
    <Text uiStyle="bold" className="mb-2">
      {i18next.t('employee.referral.info.title')}
    </Text>
    <Text type="subtext" className="mb-3">
      {i18next.t('employee.referral.info.documents')}
    </Text>
    <Text type="subtext" className="mb-2">
      {i18next.t('employee.referral.info.ask')}
    </Text>
    <Button name={i18next.t('employee.referral.info.button')} onClick={onClick} className="w-full">
      {i18next.t('employee.referral.info.button')}
    </Button>
  </div>
)

export default AgencyReferralInfo
