import React, { useState } from 'react'
import i18next from 'i18next'
import { fetchDocumentGroup } from 'employee/queries/document_groups'
import { useNavigate } from 'react-router-dom'
import { DocumentGroup } from 'employee/types/document_groups'

import Text from 'components/Text'
import DocumentChoiceRow from 'employee/components/document_groups/DocumentChoiceRow'
import DocumentGroupPageContainer from 'employee/components/document_groups/DocumentGroupPageContainer'
import DocumentUploadFlow from 'employee/components/document_groups/DocumentUploadFlow'
import { displayStatusTagForDocumentGroup } from 'employee/utils/document_groups'
import { useDocumentAdvice } from 'employee/hooks/useDocumentAdvice'

const OVERRIDE_DOCUMENTS_SLUG_TO_LABEL: Record<string, string> = {
  employee_address_proof: 'document_group_config.overrides.employee_address_proof',
}

const displayOverrideDocumentLabel = (slug: string) => {
  const override = OVERRIDE_DOCUMENTS_SLUG_TO_LABEL[slug]
  if (!override) return
  return i18next.t(override)
}

const requestOtherMissingRequiredDocuments = (documentGroup: DocumentGroup, uploadedDocumentSlug: string) => {
  const { subDocumentGroups, requiredDocumentSlugs } = documentGroup

  if (requiredDocumentSlugs.length === 0) {
    return false
  }

  const uploadedRequiredDocumentSlugs = subDocumentGroups
    .filter(
      (subDocumentGroup) => subDocumentGroup.status === 'valid' && requiredDocumentSlugs.includes(subDocumentGroup.slug)
    )
    .map((subDocumentGroup) => subDocumentGroup.slug)

  if (requiredDocumentSlugs.includes(uploadedDocumentSlug)) {
    uploadedRequiredDocumentSlugs.push(uploadedDocumentSlug)
  }

  if (uploadedRequiredDocumentSlugs.length < requiredDocumentSlugs.length) {
    return true
  }

  return false
}

const DocumentGroupList = ({ documentGroupId, slug }: { documentGroupId: string; slug: string }) => {
  const navigate = useNavigate()

  const [documentToUpload, setDocumentToUpload] = useState<DocumentGroup | null>()
  const { AdviceComponent, showAdvice, setShowAdviceIfNeeded, hideAdvice } = useDocumentAdvice()

  const documentGroup = fetchDocumentGroup(documentGroupId, slug)

  const onDocumentClick = (selectedDocumentGroup: DocumentGroup) => {
    const documentGroupFunnelPath = `/document_groups/${documentGroupId}/${selectedDocumentGroup.slug}/list`
    const existingDocumentPath = `/document_groups/${documentGroupId}/${selectedDocumentGroup.slug}`
    if (selectedDocumentGroup.kind === 'list') {
      navigate(documentGroupFunnelPath)
    } else if (selectedDocumentGroup.status === 'valid') {
      navigate(existingDocumentPath)
    } else {
      setShowAdviceIfNeeded(selectedDocumentGroup.slug)
      setDocumentToUpload(selectedDocumentGroup)
    }
  }

  const displayStatusTag = displayStatusTagForDocumentGroup(documentGroup)

  const onFinish = () => {
    if (!documentGroup || !documentToUpload) return

    if (requestOtherMissingRequiredDocuments(documentGroup, documentToUpload.slug)) {
      setDocumentToUpload(null)
      return
    }
    navigate('/document_groups')
  }

  return (
    <DocumentGroupPageContainer id="documentGroupList" onBackPressed={() => navigate(-1)} iconName="add">
      <div className="text-center mb-10">
        <Text type="title">{i18next.t(`document_group_config.${slug}_title`)}</Text>
        <Text type="subtext">{i18next.t(`document_group_config.${slug}_subtitle`)}</Text>
      </div>
      <div className="flex flex-col gap-y-3 overflow-auto">
        {documentGroup?.subDocumentGroups.map((subDocumentGroup) => (
          <DocumentChoiceRow
            key={subDocumentGroup.label}
            label={displayOverrideDocumentLabel(subDocumentGroup.slug) ?? subDocumentGroup.label}
            status={subDocumentGroup.status}
            onClick={() => onDocumentClick(subDocumentGroup)}
            displayStatusTag={displayStatusTag}
          />
        ))}
      </div>

      {documentToUpload && !showAdvice ? (
        <DocumentUploadFlow
          slug={documentToUpload.slug}
          documentGroupId={documentGroupId}
          onClose={() => setDocumentToUpload(null)}
          onFinish={onFinish}
        />
      ) : null}
      {showAdvice && (
        <AdviceComponent
          onContinueClick={hideAdvice}
          onClose={() => {
            hideAdvice()
            setDocumentToUpload(null)
          }}
        />
      )}
    </DocumentGroupPageContainer>
  )
}

export default DocumentGroupList
