import React from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import useDocumentTitle from 'hooks/useDocumentTitle'

type PageWrapperProps = {
  paramKeys?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (params: any) => React.JSX.Element | null
  titleKey: string
}

const PageWrapper = ({ paramKeys, component: Component, titleKey, ...props }: PageWrapperProps) => {
  const paramsFromUrl = useParams()

  const params: Record<string, string> = {}

  useDocumentTitle(i18next.t(titleKey))

  if (paramKeys) {
    paramKeys.forEach((key: string) => {
      params[key] = paramsFromUrl[key] ?? ''
    })
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...params} {...props} />
}

export default PageWrapper
