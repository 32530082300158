import { DocumentGroup } from 'employee/types/document_groups'
import { trackEvent } from 'utils/amplitude'

type ShowNotOwnedDocumentButtonProps = {
  documentGroup: DocumentGroup
  page: 'first_file_selection' | 'uploaded_document_list'
}

export const trackNotOwnedEvent = (slug: string) => trackEvent('not-owned-document', { documentSlug: slug })

export const showNotOwnedDocumentButton = ({ documentGroup, page }: ShowNotOwnedDocumentButtonProps) => {
  if (!documentGroup.allowBlank) {
    return false
  }
  if (documentGroup.status !== 'missing') {
    return false
  }
  if (documentGroup.kind === 'document' && page === 'first_file_selection') {
    return true
  }

  if (documentGroup.kind === 'folder' && page === 'uploaded_document_list') {
    return true
  }

  return false
}
