import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DocumentGroupStatus } from 'types/document_groups'
import {
  DocumentGroup,
  TDocumentGroupWithExpiration,
  TDocumentGroupList,
  TDocumentGroupKind,
} from 'employee/types/document_groups'
import httpClient from 'utils/http-client'
import postFile from 'utils/post-file'
import { EmployeeUploadedDocument } from 'employee/types/documents'

const DOCUMENT_GROUPS_KEY = ['document_groups']

const getDocumentGroupKey = (documentGroupId?: string) => [...DOCUMENT_GROUPS_KEY, documentGroupId]

type DocumentGroupPayload = {
  id: string
  label: string
  kind: TDocumentGroupKind
  slug: string
  sub_document_groups: DocumentGroupPayload[]
  status: DocumentGroupStatus
  double_sided: boolean
  allow_blank: boolean
  required_document_slugs: string[]
}

type TDocumentGroupShow = DocumentGroupPayload & {
  expiration_date?: string
  requested_by: string[]
  documents: Record<string, EmployeeUploadedDocument[]>
}

type TDocumentGroupIndex = {
  needs_action: DocumentGroupPayload[]
  ok: DocumentGroupPayload[]
  not_owned: DocumentGroupPayload[]
}

const formatDocumentGroups = (documentGroups: DocumentGroupPayload[]): DocumentGroup[] =>
  documentGroups.map(
    ({ id, label, kind, slug, sub_document_groups, status, double_sided, allow_blank, required_document_slugs }) => ({
      id,
      label,
      kind,
      slug,
      status,
      requiredDocumentSlugs: required_document_slugs,
      subDocumentGroups: formatDocumentGroups(sub_document_groups),
      doubleSided: Boolean(double_sided),
      allowBlank: Boolean(allow_blank),
    })
  )

export const useDocumentGroups = () =>
  useQuery<TDocumentGroupList>({
    queryKey: DOCUMENT_GROUPS_KEY,
    queryFn: () =>
      httpClient<TDocumentGroupIndex>('/document_groups').then((documentGroupIndex) => ({
        needsAction: formatDocumentGroups(documentGroupIndex.needs_action),
        ok: formatDocumentGroups(documentGroupIndex.ok),
        notOwned: formatDocumentGroups(documentGroupIndex.not_owned),
      })),
    placeholderData: {
      needsAction: [],
      ok: [],
      notOwned: [],
    },
  })

export const useInvalidateDocumentGroupsQuery = () => {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: DOCUMENT_GROUPS_KEY })
}

export const useDocumentGroup = (documentGroupId: string) =>
  useQuery<TDocumentGroupWithExpiration>({
    queryKey: getDocumentGroupKey(documentGroupId),
    queryFn: () =>
      httpClient<TDocumentGroupShow>(`/document_groups/${documentGroupId}`).then((documentGroup) => ({
        id: documentGroup.id,
        label: documentGroup.label,
        requestedBy: documentGroup.requested_by,
        kind: documentGroup.kind,
        slug: documentGroup.slug,
        subDocumentGroups: formatDocumentGroups(documentGroup.sub_document_groups),
        documents: documentGroup.documents,
        status: documentGroup.status,
        requiredDocumentSlugs: [],
        doubleSided: Boolean(documentGroup.double_sided),
        allowBlank: documentGroup.allow_blank,
      })),
    initialData: {
      id: '',
      label: '',
      requestedBy: [],
      kind: 'document',
      slug: '',
      subDocumentGroups: [],
      documents: {},
      requiredDocumentSlugs: [],
      status: 'missing',
      doubleSided: false,
      allowBlank: false,
    },
    enabled: !!documentGroupId,
  })

export const fetchDocumentGroup = (documentGroupId: string, slug: string): DocumentGroup | undefined => {
  const { data: documentGroups } = useDocumentGroups()

  if (!documentGroups) return undefined

  const foundDocumentGroup = [...documentGroups.ok, ...documentGroups.needsAction].find(
    (documentGroup) => documentGroup.id === documentGroupId
  )

  if (!foundDocumentGroup) return undefined

  if (!slug) return foundDocumentGroup

  const findSubDocumentWithSlug = (currentDocumentGroups: DocumentGroup[]): DocumentGroup | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const subDocumentGroup of currentDocumentGroups) {
      if (subDocumentGroup.slug === slug) {
        return subDocumentGroup
      }
      const foundSubDocument = findSubDocumentWithSlug(subDocumentGroup.subDocumentGroups)
      if (foundSubDocument) {
        return foundSubDocument
      }
    }

    return undefined
  }

  return findSubDocumentWithSlug([foundDocumentGroup])
}

type UseDocumentUploadArgs = { onSuccess?: () => void; onError?: (error: Error) => void }

export const useDocumentUpload = ({ onSuccess, onError }: UseDocumentUploadArgs = {}) =>
  useMutation({
    mutationFn: async ({ documentGroup, slug, file }: { documentGroup: DocumentGroup; slug: string; file: File }) =>
      postFile(`/document_groups/${documentGroup.id}/documents`, file, { kind_slug: slug }),
    onSuccess,
    onError,
  })

type UseDocumentDestroyArgs = { onSuccess?: () => void; onError?: (error: Error) => void }

export const useDocumentDestroy = ({ onSuccess, onError }: UseDocumentDestroyArgs = {}) => {
  const invalidateDocumentGroupsQuery = useInvalidateDocumentGroupsQuery()
  return useMutation({
    mutationFn: ({ documentGroupId, documentId }: { documentGroupId: string; documentId: string }) =>
      httpClient(`/document_groups/${documentGroupId}/documents/${documentId}`, {
        method: 'delete',
      }),
    onSuccess: () => {
      invalidateDocumentGroupsQuery()
      if (onSuccess) {
        onSuccess()
      }
    },
    onError,
  })
}
