import React from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import Divider from 'components/Divider'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import Text from 'components/Text'
import { AgencyWithStatus, fetchAgency, useAcceptInviteAgencies } from 'employee/queries/agencies'
import Button from 'components/Button'

type AgencyProps = {
  agency: AgencyWithStatus
}

const Agency = ({ agency }: AgencyProps) => {
  const navigate = useNavigate()
  const { mutate: acceptInvite } = useAcceptInviteAgencies(agency.id, {
    onSuccess: () => {
      navigate('/document_groups')
      toast.success(i18next.t('employee.agency_list.invite_accepted'))
    },
    onError: () => {
      toast.error(i18next.t('common.unexpected_error'))
    },
  })

  return (
    <div>
      <div className="flex grow justify-between p-6 mb-4">
        <IconButton label={i18next.t('common.back')} name="arrow_back" onClick={() => navigate(-1)} />
        <Text type="subtitle">{agency.name}</Text>
        <Icon name="arrow_back" className="text-white" />
      </div>

      <div className="px-6 mt-8 mb-2">
        <Text type="subtitle" className="mb-6">
          {i18next.t('agencies.informations')}
        </Text>
        <Divider />
        <div className="flex flex-row gap-3 py-3 items-start">
          <Icon name="location_on" />
          <div>
            <Text uiStyle="bold">{agency.name}</Text>

            <Text>{agency.address}</Text>
          </div>
        </div>
        <Divider />

        {agency.phone_number && (
          <div className="flex flex-row gap-3 py-3 items-start">
            <Icon name="phone" />
            <a href={`tel:${agency.phone_number}`}>
              <Text>{agency.phone_number}</Text>
            </a>
          </div>
        )}

        <Divider />
        {agency.status === 'pending' ? (
          <Button className="w-full mt-4" type="button" name={`accept ${agency.name}`} onClick={acceptInvite}>
            {i18next.t('employee.agency_list.accept_invitation')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

type AgencyShowProps = {
  agencyId: string
}

const AgencyShow = ({ agencyId }: AgencyShowProps) => {
  const agency = fetchAgency(agencyId)

  if (!agency) {
    return null
  }

  return <Agency agency={agency} />
}

export default AgencyShow
