import { useQuery } from '@tanstack/react-query'
import { Employee, EmployeeFromEndpoint } from 'employee/types/employees'
import httpClient from 'utils/http-client'

const EMPLOYEE_KEY = ['employee']

export const useEmployee = () =>
  useQuery<Employee>({
    queryKey: EMPLOYEE_KEY,
    queryFn: () =>
      httpClient<EmployeeFromEndpoint>('/employee').then((data) => ({
        id: data.id,
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
      })),
    initialData: {
      id: '',
      email: '',
      lastName: '',
      firstName: '',
    },
  })
