import React from 'react'
import Modal from 'react-modal'
import i18next from 'i18next'
import Document from 'employee/components/document_groups/Document'
import { TDocumentGroupWithExpiration } from 'employee/types/document_groups'
import { createFileObject } from 'utils/documents'

type ConfirmationStepProps = {
  files: File[]
  slug: string
  documentGroup: TDocumentGroupWithExpiration
  isPending: boolean
  onConfirm: () => void
}

const ConfirmationStep = ({ files, slug, documentGroup, isPending, onConfirm }: ConfirmationStepProps) => (
  <Modal
    isOpen
    overlayClassName="bg-white fixed inset-0 flex justify-center items-end w-full"
    className="max-w-3xl min-h-dvh grow overflow-y-scroll"
  >
    <Document
      slug={slug}
      documents={files.map((file, index) => createFileObject(file, `Page ${index + 1}`))}
      documentGroup={documentGroup}
      buttonLoading={isPending}
      buttonLabel={i18next.t('employee.document_groups.document_group_upload.add_file')}
      onClick={onConfirm}
    />
  </Modal>
)

export default ConfirmationStep
